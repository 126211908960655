import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import ParallaxCode from "../Components/ads/ParallaxCode";
import BelowFeaturedImages from "../Components/ads/BelowFeaturedImages";
import SocialShareButtons from "../Components/Common/SocialShareButtons";
import StickyAd from "../Components/Common/StickywithSubBLog";
import BlogWithParallax from "../Components/Common/BlogWithParallax";
import LeftSideAd from "../Components/ads/LeftSideAd";
import RightSideAd from "../Components/ads/RightSideAd";
import decrypt from "../Components/Common/DecryptData";

const EarnerData = () => {
  const location = useLocation();
  const earnName = location.pathname.split("/").pop();
  const [earnData, setEarnData] = useState();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1440);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1440);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchEarnerData = async () => {
      try {
        // Fetch encrypted data from the API
        const response = await axios.get(
          `${Base_Url}api/top-earners/${earnName}`
        );
        const { iv, end } = response.data; // Adjust based on your API's response

        // Decrypt data
        const decryptedContent = JSON.parse(decrypt(iv, end));
        setEarnData(decryptedContent);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching or decrypting blog data:", error);
        setLoading(false);
      }
    };

    if (earnName) fetchEarnerData();
  }, [earnName]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      {isDesktop && (
        <>
          <LeftSideAd />
          <RightSideAd />
        </>
      )}

      {loading ? (
        <div className="flex justify-center items-center md:min-h-screen min-h-[50vh]">
          <div className="loader2 my-5"></div>
        </div>
      ) : (
        <div className="container max-w-7xl mx-auto px-4 mt-4">
          <h3
            className="text-[40px] text-center font-semibold"
            style={{
              fontFamily: "Playfair Display",
              fontSize: "clamp(1.5rem, 4vw, 2.8rem)",
            }}
          >
            {earnData?.title}
          </h3>
          <div className="flex justify-center my-3">
            <img
              src="https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/billionaireclubs/Layer_1.jpg"
              alt=""
              style={{ width: "clamp(150px, 50%, 250px)" }}
            />
          </div>
          <div className="sm:h-[550px] mt-4">
            <img
              src={earnData?.image}
              alt=""
              className="h-full w-full object-cover object-top"
            />
          </div>
          <SocialShareButtons blogTitle={earnData?.title} />
          <ParallaxCode />
          <div className="flex gap-5">
            <div className="lg:w-[70%] headline-description">
              {earnData?.description && (
                <BlogWithParallax description={earnData?.description} />
              )}
            </div>
            <StickyAd
              link={"blog"}
              url={`${Base_Url}api/get-blog?limit=100&page=1`}
              StickyContent={<BelowFeaturedImages />}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default EarnerData;
