import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Base_Url from "../api/baseUrl";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import BackToTop from "../Components/backToTop";
import TopEarner from "../Components/Top-earner";
import CelebrityEvents from "./Celebrity-events";
import BlogPage from "./Blog-page";
import ViewFinancialIdea from "../Components/view financial-idea";
import ParallaxCode from "../Components/ads/ParallaxCode";
import BelowFeaturedImages from "../Components/ads/BelowFeaturedImages";
import BetweenContent from "../Components/ads/BetweenContent";
import EndOfArticle from "../Components/ads/EndOfArticle";
import LeftSideAd from "../Components/ads/LeftSideAd";
import RightSideAd from "../Components/ads/RightSideAd";
import decrypt from "../Components/Common/DecryptData";

// Separate component for SkeletonLoader
const SkeletonLoader = () => (
  <div className="border border-gray-200 p-2 h-fit animate-pulse">
    <div className="text-center">
      <div className="bg-gray-300 h-8 w-3/4 mx-auto mt-3"></div>
      <div className="bg-gray-300 h-5 w-1/2 mx-auto my-2"></div>
    </div>
    <div className="bg-gray-300 h-[280px] w-full"></div>
    <div className="text-center">
      <div className="bg-gray-300 h-4 w-5/6 mx-auto mt-2"></div>
      <div className="bg-gray-300 h-4 w-5/6 mx-auto mt-2"></div>
      <div className="bg-gray-300 h-4 w-5/6 mx-auto mt-2"></div>
      <div className="bg-gray-300 h-4 w-1/3 mx-auto mt-2"></div>
    </div>
  </div>
);

// Separate component for BillionaireList
const BillionaireList = ({ billionaireData, onCardClick, loading }) => {
  if (loading) {
    return Array.from({ length: 6 }).map((_, index) => (
      <SkeletonLoader key={index} />
    ));
  }

  return billionaireData.map((x, index) => (
    <div key={index}>
      <a href={`/billionaires/${x?.name?.replace(/\s+/g, "-")}`}>
        <div
          className="border p-2 h-fit cursor-pointer"
        // onClick={() => onCardClick(x?.name?.replace(/\s+/g, "-"))}
        >
          <div className="text-center mb-2">
            <h3
              className="md:text-[28px] text-[22px] font-bold mt-3 lg:px-3 line-clamp-2"
              style={{ fontFamily: "Playfair Display" }}
            >
              {x?.name}
            </h3>
            {x?.net_worth && (
              <p className="md:text-[18px] text-[16px] font-bold mt-2">
                Net Worth : {x?.net_worth}
              </p>
            )}
          </div>
          <div className="h-[280px]">
            <img
              src={x?.image}
              loading="lazy"
              alt={x?.name}
              className="h-full w-full object-cover"
            />
          </div>
          <div className="text-center">
            <p
              className="lg:max-w-[90%] mx-auto mt-2 md:text-[16px] text-[14px] tracking-[0.2px] line-clamp-2"
              dangerouslySetInnerHTML={{ __html: x?.description }}
            ></p>
            {x?.updated_at && (
              <p className="text-[14px] text-gray-500 mt-2">
                Updated on:{" "}
                {new Date(x?.updated_at).toLocaleDateString("en-US", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })}
              </p>
            )}
          </div>
        </div>
      </a>
      {index === 1 && <BelowFeaturedImages />}
      {index === 4 && <BetweenContent />}
    </div>
  ));
};

const Billionaires = () => {
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [blogLoading, setBlogLoading] = useState(false);
  const [ideaLoading, setIdeaLoading] = useState(false);
  const [billionaireData, setBilionaireData] = useState([]);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1440);
  const previousPageRef = useRef(page);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1440);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);

  const limit = 6;
  const navigate = useNavigate();

  useEffect(() => {
    const updateScreenSize = () => setIsSmallScreen(window.innerWidth < 640);
    updateScreenSize();
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  useEffect(() => {
    // Fetch data only if the page has changed
    // if (previousPageRef.current !== page) {
    const fetchBillionaireData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${Base_Url}api/get-billionaire?page=${page + 1}&limit=${limit}`
        );
        setLoading(false);
        if (response && response.data) {
          const { iv, end } = response.data;
          const decryptedResponse = decrypt(iv, end);
          const parsedData = JSON.parse(decryptedResponse);
          setBilionaireData(parsedData?.data);
          setPageCount(parsedData?.totalPage);
        }
      } catch (error) {
        console.log("Error fetching billionaire data:", error);
        setLoading(false);
      }
    };
    fetchBillionaireData();
    previousPageRef.current = page; // Update previous page after fetching
    // }
  }, [page, limit]);

  const handlePageChange = debounce((e) => {
    setPage(e.selected);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 300);

  const handleCardClick = (formattedTitle) => {
    navigate(`/billionaires/${formattedTitle}`);
  };

  return (
    <>
      {isDesktop && (
        <>
          <LeftSideAd />
          <RightSideAd />
        </>
      )}
      <div className="px-4">
        <div className="container max-w-[1400px] mx-auto pt-[2rem] lg:px-0">
          <div className="">
            <h3
              className="text-[38px] text-center font-semibold"
              style={{
                fontFamily: "Playfair Display",
                fontSize: "clamp(1.7rem, 4vw, 2.8rem)",
              }}
            >
              Unlocking Billionaires Secrets
            </h3>
            <div className="flex justify-center my-3">
              <img
                src="https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/billionaireclubs/Layer_1.jpg"
                alt=""
                style={{ width: "clamp(150px, 50%, 250px)" }}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-7 mt-9 min-h-[500px]">
            <BillionaireList
              billionaireData={billionaireData}
              onCardClick={handleCardClick}
              loading={loading}
            />
          </div>
          <div className="flex justify-center mt-[33px] max-[768px]:mt-[10px]">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={isSmallScreen ? 1 : 2}
              pageRangeDisplayed={isSmallScreen ? 1 : 3}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={page}
            />
          </div>
          <ParallaxCode />
          <div className="my-5">
            <TopEarner />
          </div>
          <EndOfArticle />
          <div className="my-5">
            <CelebrityEvents />
          </div>
          <EndOfArticle />
          <div className="mt-5">
            <BlogPage
              setBlogLoading={setBlogLoading}
              blogLoading={blogLoading}
            />
            <ViewFinancialIdea
              setIdeaLoading={setIdeaLoading}
              ideaLoading={ideaLoading}
            />
          </div>
        </div>
        <BackToTop />
      </div>
    </>
  );
};

export default Billionaires;
