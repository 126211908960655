import React, { useEffect, useState } from "react";
import EndOfArticle from "../Components/ads/EndOfArticle";
import BetweenContent from "../Components/ads/BetweenContent";
import BackToTop from "../Components/backToTop";
import LeftSideAd from "../Components/ads/LeftSideAd";
import RightSideAd from "../Components/ads/RightSideAd";
import decrypt from "../Components/Common/DecryptData";
import Base_Url from "../api/baseUrl";
import axios from "axios";

const Books = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1440);
  const [booksData, setBooksData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1440);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);

  useEffect(() => {
    const fetchBooksData = async () => {
      try {
        const response = await axios.get(
          `${Base_Url}api/books?limit=20&page=1`
        );
        const booksResponseData = response.data;
        const decryptedDataString = decrypt(
          booksResponseData.iv,
          booksResponseData.end
        );
        const decryptedbooksData = JSON.parse(decryptedDataString);
        setBooksData(decryptedbooksData?.data || []);
      } catch (error) {
        console.error("Error fetching books data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBooksData();
  }, []);

  return (
    <div>
      {isDesktop && (
        <>
          <LeftSideAd />
          <RightSideAd />
        </>
      )}
      <div className="container max-w-7xl mx-auto mt-14">
        <div className="flex justify-center">
          <h1
            className="md:text-[37px] font-bold text-[#000] text-[28px] text-center px-2"
            style={{ fontFamily: "Playfair Display" }}
          >
            Books to Read for Becoming a Rich
          </h1>
        </div>
        <div className="mt-7">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-8">
            {loading
              ? Array.from({ length: 8 }).map((_, index) => (
                  <div key={index} className="bg-white m-3 p-4 border border-[#cacaca] animate-pulse">
                    <div className="border-b border-[#cacaca]">
                      <div className="flex justify-center p-3 mb-4 md:h-60 sm:h-48 h-56 bg-gray-300" />
                    </div>
                    <div className="mt-3">
                      <div className="h-5 bg-gray-300 mb-3 mx-auto w-3/4"></div>
                      <div className="flex justify-center mt-2">
                        <div className="w-[130px] h-8 bg-gray-300" />
                      </div>
                    </div>
                  </div>
                ))
              : booksData.map((book, index) => (
                  <React.Fragment key={index}>
                    <div className="">
                      <div className="bg-white m-3 p-4 border border-[#cacaca]">
                        <div className="border-b border-[#cacaca]">
                          <div className="flex justify-center p-3 mb-4 md:h-60 sm:h-48 h-56 bg-[#F1F1F1]">
                            <img
                              src={book?.image}
                              className="object-cover"
                              alt={book?.book_name}
                            />
                          </div>
                        </div>
                        <div className="">
                          <p className="text-center line-clamp-1 font-semibold mt-3">
                            {book?.book_name}
                          </p>
                          <div className="flex justify-center mt-2">
                            <a
                              href={book?.link}
                              target="_blank"
                              rel="noreferrer"
                              className="no-underline"
                            >
                              <div className="w-[130px] ">
                                <img
                                  src="https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/billionaireclubs/buy+button.jpg"
                                  alt=""
                                  className="w-full"
                                />
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {index === 2 && (
                      <div className="lg:col-span-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-4">
                        <BetweenContent />
                      </div>
                    )}
                    {index === 5 && (
                      <div className="lg:col-span-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-4">
                        <EndOfArticle />
                      </div>
                    )}
                  </React.Fragment>
                ))}
          </div>
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default Books;
